.PopUp .ShowFile {
    width: 760px;
}
.PopUp .title_file{
    justify-content: right;
}
.PopUp .show_img{
    max-width: 100%;
}
.PopUp .aspect_video{
    aspect-ratio: 16/9;
    width: -webkit-fill-available;
}
.PopUp .div_video{
    width: -webkit-fill-available;
    height: 400px;
}
