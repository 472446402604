.Login{
    width: 100%;
    height: 100%;
    background-color: #eef0f5;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Login .content{
    width: 300px;
    height: auto;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
}
.Login .div_logo{
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}
.Login .logo{
    height: auto;
    width: 80%;
    cursor: default;
}
.Login .div_data{
    margin-top: 6px;
}
.Login .msg_error{
    width: auto;
    height: 40px;
    color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    border: 1px solid #FC4F81;
    background: linear-gradient(0deg, rgba(252, 79, 129, 0.2), rgba(252, 79, 129, 0.2)), #1C2023;
}
.Login .div_logar{
    height: 40px;
    background-color: #324D6B;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}
.Login .div_input_login{
    margin-top: 30px;
}
.Login .div_logar:hover{
    background-color: #009BA2;
}
.Login .button{
    cursor: pointer;
    width: -webkit-fill-available;
    height: 40px;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 16px;
}

.Login input{
    color: #6c757d;
    border: 2px solid #ebebeb;
    background-color: #ffffff;
}
.Login .name_input{
    color: #6c757d;
    background-color: #ffffff;
}