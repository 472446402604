.Site_HowWorks {
    padding: 20px;
    padding-bottom: 70px;
}
.Site_HowWorks .show_doubts{
    padding: 10px;
    background-color: #ffffff;
    margin-bottom: 30px;
    width: -webkit-fill-available;
}
.Site_HowWorks .title_side{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 0px;
}
.Site_HowWorks .border_questions{
    border-bottom: 1px solid #ffffff;
    padding-bottom: 20px;
    flex-direction: column;
}
.Site_HowWorks .border_questions:nth-last-child(-n+1){
    border-bottom: none;
    padding-bottom: 0px;
}
.Site_HowWorks .width_{
    width: -webkit-fill-available;
}
.Site_HowWorks .trash{
    width: 24px;
    height: 24px;
    display: block;
    position: relative;
    top: 6px;
}
.Site_HowWorks .show_doubts:nth-last-child(-n + 1) {
    margin-bottom: 0px;
}

.Site_HowWorks .div_howworks{
    display: flex;
    flex-direction: column;
}
.Site_HowWorks .div_howworks .space_div{
    width: -webkit-fill-available;
}