.Page_Courses_Details {
    padding: 20px;
    padding-bottom: 70px;
}
.Page_Courses_Details .data_select, .Page_Courses_Details .select_video{
    width: 140px;
}
.Page_Courses_Details .select_type {
    width: 220px;
}
.Page_Courses_Details .input_code{
    width: 106px;
    text-align: center
}
.Page_Courses_Details .data_input, .Page_Courses_Details .pass{
    width: 120px;
}
.Page_Courses_Details .div_high{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
}
.Page_Courses_Details .div_high .high{
    width: 80px;
    text-align: center;
    padding: 6px 0px;
    border: 2px solid #061821;
    cursor: pointer;
}
.Page_Courses_Details .div_high .high:hover{
    color: #ffffff;
    border-color: #05496c;
    background-color: #05496c;
}
.Page_Courses_Details .div_high .high_active{
    color: #ffffff;
    border-color: #061821;
    background-color: #061821;
}
.Page_Courses_Details .title_table{
    font-weight: 600;
}

/* table */
.Page_Courses_Details table th{
    background-color: transparent;
    border-bottom: 1px solid #E2E8F0;
}
.Page_Courses_Details table th, .Page_Courses_Details table td{
    color: #6c757d;
}
/* end */

.Page_Courses_Details .div_order_module{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}
.Page_Courses_Details .space_order{
    width: 24px;
    display: flex;
}