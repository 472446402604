
.PopUp .DataProfile .list_data_page{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: -webkit-fill-available;
}
.PopUp .DataProfile .title_side{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 0px;
}
.PopUp .DataProfile .div_input {
    margin: 0;
}
.PopUp .DataProfile .show_div_input{
    width: auto;
}


.PopUp .DataProfile .form_profile {
    width: -webkit-fill-available;
}
.PopUp .DataProfile .show_profile_popup{
    margin-top: 0;
    margin-bottom: 16px;
}
.PopUp .DataProfile .img_popup{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: -webkit-fill-available;
    gap: 6px;
}
.PopUp .DataProfile .position_popup{
    justify-content: flex-start !important;
}
.PopUp .DataProfile .input_name{
    width: 120px;
}
.PopUp .DataProfile .input_date{
    width: 100px;
}