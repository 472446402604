.Page_Courses_Details_Classes{
    padding: 20px;
    padding-bottom: 70px;
}
.Page_Courses_Details_Classes .data_select, .Page_Courses_Details_Classes .select_video{
    width: 140px;
}
.Page_Courses_Details_Classes .select_type {
    width: 220px;
}
.Page_Courses_Details_Classes .input_code{
    width: 106px;
    text-align: center
}
.Page_Courses_Details_Classes .free{
    border-color: #061821;
    border: 1px solid #061821;
    padding: 7px 10px;
    cursor: pointer;
}
.Page_Courses_Details_Classes .free_active{
    background-color: #111827;
    color: #ffffff;
}
.Page_Courses_Details_Classes .data_select_module{
    width: 200px;
}
.Page_Courses_Details_Classes .others{
    font-weight: 700;
    font-size: 20px;
}
.Page_Courses_Details_Classes .select_module{
    width: 200px;
}