.PopUp .StatusTeam{
    width: 400px;
}
.PopUp .StatusTeam .save_popup{
    width: 100%;
}
.PopUp .StatusTeam .content{
    gap: 20px;
}
.PopUp .StatusTeam table{
    width: auto !important;
}
.PopUp .StatusTeam .list_inf_file {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #ffffff;
    padding-bottom: 10px;
}
.PopUp .StatusTeam .list_inf_file:nth-last-child(-n+1){
    border-bottom: none;
}
.PopUp .StatusTeam .file_name{
    flex-grow: 1;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.PopUp .StatusTeam .file_data{
    text-align: left;
    width: 110px;
    min-width: 110px;
    max-width: 110px;
    padding: 0px 6px;
}
.PopUp .StatusTeam .file_download{
    width: 24px;
    min-width: 24px;
    max-width: 24px;
}
.PopUp .StatusTeam .register_data{
    margin-top: 0;
}
.PopUp .StatusTeam .list_input_data{
    width: -webkit-fill-available;
}