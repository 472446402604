.ModelSite_Profile{
    width: -webkit-fill-available;
}
.ModelSite_Profile .list_data_page{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.ModelSite_Profile .title_side{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 0px;
}
.ModelSite_Profile .img{
    width: 100%;
}
.ModelSite_Profile .img_example{
    width: 100%;
}
.ModelSite_Profile .space_bottom{
    margin-bottom: 0px;
}
.ModelSite_Profile .icons_profile{
    width: 40px;
    height: 40px;
    cursor: pointer;
}

/* example of how it looks on the website */
.ModelSite_Profile .ModelPages_TeacherProfile {
    align-items: center;
    display: flex;
    height: auto;
    justify-content: center;
    min-height: 300px;
    position: relative;
}
.ModelSite_Profile .ModelPages_TeacherProfile .show_profile {
    display: flex;
    gap: 30px;
    justify-content: center;
    margin: 0 auto;
    flex-direction: row;
    position: absolute;
}
.ModelSite_Profile .ModelPages_TeacherProfile .file {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 48%;
    max-height: 300px;
}
.ModelSite_Profile .ModelPages_TeacherProfile .img_bg {
    width: 40%;
}
.ModelSite_Profile .ModelPages_TeacherProfile .show_data {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    width: 60%;
    padding: 34px;
}
.ModelSite_Profile .ModelPages_TeacherProfile .name_profile {
    align-items: center;
    display: flex;
    gap: 20px;
}
.ModelSite_Profile .ModelPages_TeacherProfile .img_profile {
    height: 40px;
    width: 40px;
    border-radius: 40px;
}
.ModelSite_Profile .ModelPages_TeacherProfile .div_name {
    font-family: Bebas Neue, sans-serif;
    font-size: 32px;
    text-transform: uppercase;
}
.ModelSite_Profile .ModelPages_TeacherProfile .type_1_name, .ModelSite_Profile .ModelPages_TeacherProfile .type_2_name {
    color: #fff;
    opacity: .26;
}
.ModelSite_Profile .ModelPages_TeacherProfile .subname {
    color: #fff;
}
.ModelSite_Profile .ModelPages_TeacherProfile .text {
    color: #fff;
    font-family: Roboto, sans-serif;
    font-size: 10px;
    line-height: 14px;
    text-align: justify;
}
.ModelSite_Profile .ModelPages_TeacherProfile .portfolio {
    align-items: center;
    display: flex;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    gap: 10px;
    justify-content: flex-start;
    text-align: justify;
    color: #fff;
}
.ModelSite_Profile .ModelPages_TeacherProfile .background_image {
    height: 100%;
    overflow: hidden;
    width: 100%;
}
.ModelSite_Profile .ModelPages_TeacherProfile .img_home {
    height: -webkit-fill-available;
    margin-bottom: -8px;
    width: 100%;
}
.ModelSite_Profile .ModelPages_TeacherProfile .icons_ {
    width: 32px;
    height: 32px;
}
/* end */